module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-125551144-1"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://navd.ai"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
